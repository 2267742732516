<template>
	<v-container>
		<v-row id="prioridad">

			<v-col cols="12" sm="4">
				<v-card
				class="mx-auto">
				<v-card-title> Contraindicaciones/Poblaciones Especiales </v-card-title>
				<v-card-text>
					<v-simple-table>
						<template v-slot:default>
							<thead>
								<tr>
									<th > </th>
									<th > </th>
									<th>Prioridad</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td >Medicamento</td>
									<td >Paracetamol</td>
									<td> <v-checkbox></v-checkbox></td>

								</tr>
								<tr>
									<td >Vías</td>
									<td >Oral</td>
									<td> <v-checkbox></v-checkbox></td>
								</tr>
								<tr>
									<td >Forma Farmacéutica</td>
									<td >Comprimido </td>
									<td> <v-checkbox></v-checkbox></td>
								</tr>
							</tbody>
						</template>
					</v-simple-table>
				</v-card-text>
			</v-card>

			<v-btn			
			class="button-alert"
			>
			Crear Alarma
		</v-btn>

	</v-col>
	<v-col>

		<v-card class="pa-6">
			<v-card-title>Contraindicaciones</v-card-title>
			<v-data-table
			dense
			:headers="headersContraindicaciones"
			:items="itemsContraindicaciones"    
			class="elevation-1"
			:footer-props="{
			'items-per-page-text':' '
		}"
		></v-data-table>

	</v-card>


</v-col>
</v-row>

<v-row class="mb-6 caractF" >  
	<v-col cols="12"><h3>Precauciones y poblaciones especiales</h3></v-col>
	
	<v-col cols="12" sm="6">
		<v-card class="mx-auto pa-3">
			<div class="div-container container-table-yes" v-for="( datarow ,x) in sectionData" :key="x" >
				<div   class="row-table-item row-table-yes"    >				
					<div class="text">{{datarow.name}}</div>
					<div class="valuestatus" v-if="datarow.status==true"> SÍ</div> <div v-if="datarow.status==false"> NO</div><div v-else>{{ datarow.status }}</div>

				</div>

			</div>


		</v-card>
	</v-col>
	<v-col cols="12" sm="6">
		<v-card class="mx-auto pa-3">

			<div class="div-container container-table-yes" v-for="( datarow ,x) in detailData" :key="x" >
				<div   class="row-table-item row-table-yes inverted"    >				
					<div class="text">{{datarow.name}}</div>
					<div>{{ datarow.text }}</div>
				</div>

			</div>


		</v-card>
	</v-col>
</v-row>
</v-container>
</template>
<style></style>
<script>
export default{
	data: () => ({
		
		headersContraindicaciones:[
		{text:'Tipo A/R', value: 'tipo', align: 'start',},
		{text:'Edad', value:'edad', },
		{text:'Sexo', value:'sexo', },
		{text:'Diagnóstico', value:'diagnostico', },
		{text:'Parámetros', value:'parametros', },
		{text:'Descripción', value:'descripcion'} 
		], 
		itemsContraindicaciones:[
		{
			tipo:'A',
			edad: '0-5',
			sexo:'M',
			diagnostico:'CIE10',
			parametros:'37/5',
			descripcion: 'lorem ipsum'
		},
		{
			tipo:'A',
			edad: '16-50',
			sexo:'M',
			diagnostico:'CIE10',
			parametros:'01/01/4',
			descripcion: 'lorem ipsum....'
		},
		{
			tipo:'R',
			edad: 'todas las edades',
			sexo:'F',
			diagnostico:'CIE10',
			parametros:'54/5/15',
			descripcion: 'lorem ipsum'
		},

		],


		sectionData:[
		{
			name: 'Modificación de la pauta en insuficiencia hepática',
			tag: 'insuficiencia_hepatica',                              
			iconalt:'hepaticIco little',
			status: true
		},
		{
			name: 'Modificación de la pauta en insuficiencia renal',
			tag:'insuficiencia_renal',                                                                 
			iconalt:'renalIco little',
			status: true
		},
		{
			name: 'Control valores plasmáticos',
			tag:'control_valores_plasmaticos',
			icon:'',                                  
			status: false
		},
		{
			name: 'Observaciones Teratogenia',
			tag: 'teratogenia',
			icon:'mdi-asterisk',
			status: false
		},
		{
			name: 'Categorización Seguridad Lactancia',
			tag: 'lactancia',
			icon:'',
			status: false
		},
		{
			name: 'Antídoto',
			tag: 'antidoto',
			icon:'',
			status: 'Sí -  N-acetilcisteína'
		},
		],

		detailData:[
		{
			name: 'Efectos Adversos Graves',
			text: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Expedita illo saepe, nihil ut reiciendis eos beatae, rem corporis odio nesciunt?',                              

		},
		{
			name: 'Efectos Adversos Frecuentes',
			text:'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Expedita illo saepe, nihil ut reiciendis eos beatae, rem corporis odio nesciunt?',                                                                 

		},
		{
			name: 'Efectos Adversos Ocasionales',
			text:'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Expedita illo saepe, nihil ut reiciendis eos beatae, rem corporis odio nesciunt?',

		},
		{
			name: 'Efectos Adversos Raros',
			text: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Expedita illo saepe, nihil ut reiciendis eos beatae, rem corporis odio nesciunt?',

		},
		{
			name: 'Recomendaciones Usos',
			text: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Expedita illo saepe, nihil ut reiciendis eos beatae, rem corporis odio nesciunt?',

		},
		{
			name: 'Precauciones',
			text: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Expedita illo saepe, nihil ut reiciendis eos beatae, rem corporis odio nesciunt?',

		},
		{
			name: 'Monitorización',
			text: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Expedita illo saepe, nihil ut reiciendis eos beatae, rem corporis odio nesciunt?',

		},
		{
			name: 'Conducción',
			text: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Expedita illo saepe, nihil ut reiciendis eos beatae, rem corporis odio nesciunt?',
		}
		],
	}), 
}
</script>
